<template>
  <!-- begin::kb-header -->
    <header v-if="!isQrShow && !isMobileCalendarShow" class="kb-header" id="kb-header">
      <div class="header-component">
        <!-- header-title -->
        <div class="header-column header-title">
          LIVE 편성표
        </div>
        <!-- //header-title -->
      </div>
    </header>

    <!-- end::kb-header -->
    <!-- begin::kb-main -->
    <main v-if="!isQrShow && !isMobileCalendarShow && !applyModal" class="kb-main" id="kb-live">
      <!-- main-content -->
      <div class="main-content min-component component-padding">

        <LiveWeekCalendar
            :is-loading="isLoading"
            :is-mobile="true"
            :day-func="dayFunc"
            ref="weekCalRef"
        />

        <!-- section:일정 -->
        <section class="content-section">
          <div class="time-menu">
            <ul>
              <li><a href="javascript:" @click="dayFunc('')"><span>오늘</span></a></li>
              <li><a href="javascript:" @click="isMobileCalendarShow = !isMobileCalendarShow"><span><img class="icon-cal" src="@/assets/lxp/mobile/images/live/ic_cal.svg"></span></a></li>
            </ul>
          </div>
          <div class="live-table">
            <div v-if="bodies.length == 0" class="contents-empty"><img src="/app/img/img_empty.30f8e704.png" alt="">
              <p class="text">오늘 예정된 학습이 없습니다.</p>
            </div>

            <ul v-else class="live-table-list">
              <!-- live-table-item -->
              <li v-for="(item, idx) in bodies" :key="idx" class="live-table-item">
                <article class="live-table-article">
                  <div class="article-row">
                    <div class="article-left">
                      <div class="time">{{timestampToDateFormat(item.objBgngDt, 'hh:mm')}} - {{timestampToDateFormat(item.objEndDt, 'hh:mm')}}</div>
                      <div class="title-img" v-if="item.lrnObjTyCd === '100008'"><img src="@/assets/lxp/mobile/images/live/live_title_cloudnow.svg" alt="" /></div>
                      <div class="title-img" v-if="item.lrnObjTyCd === '100026'"><img src="@/assets/lxp/mobile/images/live/live_title_learninglive.svg" alt="" /></div>
                    </div>
                    <div class="article-right">
                      <h5 class="desc-title">{{item.distCrseNm}}</h5>
                      <p class="desc-meta">{{item.objNm}}</p>
                      <p class="desc-context">{{item.objSumup}}</p>
                    </div>
                  </div>
                  <div class="article-row article-actions">
                    <button v-if="item.ntcCnt > 0" @click="goNtcBoard(item.distCrseSn)" class="kb-btn kb-btn-xs kb-btn-outline-dark">
                      <span class="text">공지사항</span>
                    </button>
                    <button v-if="item.qrYn === 'Y'" @click="showQrLayer(item)" class="kb-btn kb-btn-xs kb-btn-outline-dark">
                      <span class="text">출결 QR코드</span>
                    </button>
                    <button class="kb-btn kb-btn-xs kb-btn-primary" :class="{'kb-btn-gray' : item.entranceYn !== 'ING'}" @click="enterNowClass(item)"><span class="text">입장하기</span></button>
                  </div>
                </article>
              </li>
              <!--  러닝타운 나중에 추가   -->
              <!-- live-table-item -->
  <!--            <li class="live-table-item">-->
  <!--              <article class="live-table-article">-->
  <!--                <div class="article-row">-->
  <!--                  <div class="article-left">-->
  <!--                    <div class="time">13:00 - 14:10</div>-->
  <!--                    <div class="title-img"><img src="../assets/images/live/live_title_learninglive.svg" alt="" /></div>-->
  <!--                  </div>-->
  <!--                  <div class="article-right">-->
  <!--                    <h5 class="desc-title">러닝타운</h5>-->
  <!--                    <p class="desc-meta">가계여신 신박한 정리</p>-->
  <!--                    <p class="desc-context">본부부서 가계여신 특강</p>-->
  <!--                  </div>-->
  <!--                </div>-->
  <!--                <div class="article-row article-actions">-->
  <!--                  <button class="kb-btn kb-btn-xs kb-btn-primary"><span class="text">입장하기</span></button>-->
  <!--                </div>-->
  <!--              </article>-->
  <!--            </li>-->
              <!-- live-table-item -->
            </ul>
          </div>
          <div class="warning-text">
            <p class="text">진행되는 강의 영상을 녹화한 경우 저작권 문제가 발생합니다. 법적조치가 취해질 수 있으니 영상촬영을 금지합니다. 자격증 과정은 녹화방송을 제공하지 않습니다.
            </p>
          </div>
        </section>
        <!-- //section:일정 -->

        <!-- //[yyy:221115] 수정 -->
      </div>
      <!-- //main-content -->

    </main>

    <MobileQrLayer v-if="isQrShow" v-model:isQrShow="isQrShow" :mobileShowQrObj="mobileShowQrObj"/>
    <MobileLiveCalendarDatePicker v-if="isMobileCalendarShow" v-model:isMobileCalendarShow="isMobileCalendarShow" v-model:selectDate="selectDate"/>

    <MobileCloudNowPledgeModal v-if="applyModal"
                       v-model="applyModal"
                       :distCrseSn="pledge.distCrseSn"
                       :lrnerId="pledge.lrnerId"
                       :lrnerNm="pledge.lrnerNm"/>

  <!-- end::kb-main -->
</template>

<script>
import {ref, reactive, computed, onMounted, watch} from "vue";
import {getItems, lengthCheck, timestampToDateFormat} from "@/assets/js/util";
import {useStore} from "vuex";
import {ACT_BOARD_GET_QR, ACT_GET_MY_TRGT_LIST} from "@/store/modules/live/live";
import {getThumbUrl} from "@/assets/js/modules/course/course-common";
import router from "@/router";
import MobileQrLayer from "@/views/pages/live/mobile/MobileQrLayer";
import LiveWeekCalendar from "@/components/live/LiveWeekCalendar";
import MobileLiveCalendarDatePicker from "@/components/live/MobileLiveCalendarDatePicker";
import {entranceCloudNowOnCheck} from "@/assets/js/modules/cloud/cloud-common";
import MobileCloudNowPledgeModal from "@/components/cloudNow/MobileCloudNowPledgeModal";
export default {
  name: "MobileLiveCalendarMng",
  components: {MobileLiveCalendarDatePicker, LiveWeekCalendar, MobileQrLayer, MobileCloudNowPledgeModal},
  setup() {

    const store = useStore();
    const search = reactive({
      crseMstSn: 0,
      distCrseSn: 0,
      objBgngDt: timestampToDateFormat(new Date().getTime(), 'yyyy-MM-dd') ,
      lrnObjTyCd: '100008',
    });
    const sessionId = computed(() => store.state.auth.session.lrnerId);
    const session = computed(() => store.state.auth.session);
    const isLoading = ref(true);
    const bodies = ref([]);
    const weekCalRef = ref(null)
    const toggles = ref({objBgngDt: false})

    const isQrShow = ref(false);

    const isMobileCalendarShow = ref(false);
    const selectDate = ref(null);
    const applyModal = ref(false);

    const pledge = reactive({
      distCrseSn: '',
      lrnerId:'',
      lrnerNm:''
    });

    onMounted(() => {
      getCloudNowList();
    })

    const getCloudNowList = () => {
      store.dispatch(`live/${ACT_GET_MY_TRGT_LIST}`, {
        objBgngDt: search.objBgngDt + ' 00:00:00',
        objType: '100008',
        lrnerId: sessionId.value,
        orderByCond: 'Y'
      }).then(res => {
        if (lengthCheck(res)) {
          bodies.value = getItems(res);
          if(session.value.susdyCdDcd !== '2109001'&& session.value.susdyCdDcd !== '2109002'){
            bodies.value = bodies.value.filter(x => x.lrnObjTyCd === '100008')
          }
          bodies.value.map((x) => {
            // 시작전, 진행중, 종료로 나뉨
            if((new Date(x.objBgngDt).getTime() - new Date().getTime())/1000/60 > 10){
              x.entranceYn = 'BEF'
            }else if(((new Date(x.objBgngDt).getTime() - new Date().getTime())/1000/60 < 10) && x.objEndDt > new Date().getTime()) {
              if(x.objEndYn === 'Y'){
                x.entranceYn = 'END';
              }else{
                x.entranceYn = 'ING'
              }
            }else{
              if(x.objEndYn === 'Y'){
                x.entranceYn = 'END';
              }else{
                x.entranceYn = 'ING'
              }
            }
          })
        }else{
          bodies.value = [];
        }

        getLrnBoardQr();
      }).catch(e=>{
        console.error(e);
      })
      isLoading.value = false;
    }

    const qrItems = ref([]);
    const getLrnBoardQr = () => {
      store.dispatch(`live/${ACT_BOARD_GET_QR}`, {
        divCdDcd: 2013005, // qr코드
        lrnerId: sessionId.value,
        expsrBgngDt: search.objBgngDt + ' 00:00:00',
      }).then(res=> {
        if(lengthCheck(res)){
          qrItems.value = getItems(res);
          bodies.value.map((y) => {
            qrItems.value.map((x) => {
              if(x.distCrseSn === y.distCrseSn){
                y.qrYn = 'Y';
                y.itnPath = x.itnPath;
                y.etnPath = x.etnPath;
              }
            })
          })
        }else{
          qrItems.value = [];
        }
      }).catch(e => {
        console.error(e);
      });
    }

    const dayFunc = (year, month, day) => {
      if(year == ''){
        // search.objBgngDt = timestampToDateFormat(new Date().getTime(), 'yyyy-MM-dd')
        let obj = {year: new Date().getFullYear(), month: new Date().getMonth()+1, day: new Date().getDate()}
        weekCalRef.value.selectDate(obj, true);
      }else{
        search.objBgngDt = `${year}-${('0' + month).slice(-2)}-${('0' + day).slice(-2)}`;
      }
      getCloudNowList();
    }

    // 나우 입장

    const enterNowClass = (item) => {
      const params = {
        distCrseSn : item.distCrseSn,
        objDistSn: item.lrnObjDtlDistSn,
        lrnObjDtlDistSn: item.lrnObjDtlDistSn,
        objType: item.lrnObjTyCd,
        lrnerId: session.value.lrnerId,
        etnRefDtl: item.etnRefDtl
      }
      if (item.pledgeYn === "Y" && item.pledgeRsltYn != "Y") {
        pledge.distCrseSn = item.distCrseSn;
        pledge.lrnerId = session.value.lrnerId;
        pledge.lrnerNm = session.value.lrnerNm;
        applyModal.value = true;
      } else {
        entranceCloudNowOnCheck(params);
      }
    }

    const mobileShowQrObj = ref();
    const showQrLayer = (item) => {
      isQrShow.value = true;
      mobileShowQrObj.value = item
    }

    const goNtcBoard = (distCrseSn) => {
      router.push({name: 'LiveNotice', params: {distCrseSn: distCrseSn}})
    }

    watch(() => selectDate.value, () => {
      setTimeout(() => {
        weekCalRef.value.selectDate({year: selectDate.value.year, month: selectDate.value.month, day: selectDate.value.day}, true);
      },0)
    })

    watch(() => applyModal.value, () => {
      if (applyModal.value === false) {
        getCloudNowList();
      }
    })

    return{
      isLoading,
      bodies,
      qrItems,
      weekCalRef,
      search,
      toggles,
      isQrShow,
      mobileShowQrObj,
      isMobileCalendarShow,
      selectDate,
      dayFunc,
      enterNowClass,
      timestampToDateFormat,
      getThumbUrl,
      goNtcBoard,
      applyModal,
      pledge,
      showQrLayer,
    }
  }
}
</script>

<style scoped>
.kb-btn-gray {
  background-color: #E8E8E8 !important;
}
</style>